.App {
  .title {
    padding-top: 32px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
  }
}

.Input {
  .analyzing {
    margin-left: 20px;
  }
}

.Result {
  padding-top: 32px;

  @media screen and (max-width: 600px) {
    .left {
      margin-bottom: 64px;
    }

    .right {
      text-align: center;
    }
  }

  .left {
    text-align: center;
  }

  .right {
    .profile_image {
      img {
        width: 100%;
      }
    }
  }

  .close-button {
    margin-top: 32px;
  }

  .username {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  .user_data {
    span.title {
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
    }
    span.value {
      font-size: 20px;
    }
  }
}



